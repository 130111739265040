import { useMemo } from "react";
import { Button } from "semantic-ui-react";

import { useAppSelector } from "../../../store/hooks";
import { defaultFilter } from "../../../store/shipmentSlice";

type ShipmentPanelActionsProps = {
  deleteAllClick: (open: boolean) => void;
  addShipment: () => void;
  toggleShellShipmentModal: () => void;
};

const ShipmentPanelActions = (props: ShipmentPanelActionsProps) => {
  const filter = useAppSelector((state) => state.shipments.filter);

  const filterIsApplied = useMemo(
    () => filter.customerId !== defaultFilter.customerId,
    [filter]
  );

  return (
    <>
      <Button
        size="mini"
        onClick={props.addShipment}
        icon="plus"
        content="New Shipment"
      />
      <Button
        size="mini"
        onClick={props.toggleShellShipmentModal}
        icon="plus square"
        content="Create Shell"
      />
      <Button
        size="mini"
        onClick={() => props.deleteAllClick(true)}
        content="Clear Filtered Drafts"
        icon="trash alternate"
        disabled={!filterIsApplied}
      />
    </>
  );
};

export default ShipmentPanelActions;
