import DatePicker from "react-datepicker";
import debounce from "lodash-es/debounce";
import { useState, useMemo, ChangeEvent, FormEvent, useEffect } from "react";
import {
  Form,
  Select,
  Input,
  Search,
  InputOnChangeData,
} from "semantic-ui-react";
import { Control, Controller } from "react-hook-form";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { getTurvoLocations } from "../../store/turvoLocationSlice";
import { LocationResultObject } from "../../models/LocationResultObject";
import { RouteType } from "../../models/RouteType";
import { FormLocationObject } from "../../models/FormLocationObject";
import { BaseFormObject } from "../../models/BaseFormObject";

export type RouteItemProps = {
  type: "pickup" | "delivery";
  control: Control<BaseFormObject>;
  route: FormLocationObject;
  startDateOnly: boolean;
};

const RouteItem = (props: RouteItemProps) => {
  const dispatch = useAppDispatch();
  const [locationSearch, setLocationSearch] = useState<string>(
    props.route.name
  );

  const turvoLocations = useAppSelector(
    (state) => state.turvoLocation.locations
  );
  const turvoLocationsLoading = useAppSelector(
    (state) => state.turvoLocation.status
  );

  useEffect(() => {
    setLocationSearch(props.route.name);
  }, [props.route.name]);

  const pickupTypeOptions: RouteType[] = [{ key: 1, value: 1, text: "Pickup" }];
  const deliveryTypeOptions: RouteType[] = [
    { key: 1, value: 1, text: "Delivery" },
  ];

  const type = props.type === "pickup" ? "Pickup" : "Delivery";

  const debouncedSearchChange = useMemo(
    () =>
      debounce((value: string) => {
        dispatch(getTurvoLocations(value))
          .unwrap()
          .catch((err) => {
            console.error(err);
          });
      }, 500),
    [dispatch]
  );

  const onSearchChange = (
    _: ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    setLocationSearch(value);
    debouncedSearchChange(value);
  };

  return (
    <>
      <Form.Group>
        <Form.Field
          id={`${props.type}.type`}
          control={Select}
          label="Type"
          placeholder="Type"
          options={
            props.type === "pickup" ? pickupTypeOptions : deliveryTypeOptions
          }
          value={1}
          width={2}
          fluid
        />
        <Controller
          name={`${props.type}.poNumber`}
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id={`${props.type}.poNumber`}
              control={Input}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
              label={`${type}#`}
              placeholder={`${type}#`}
              width={2}
            />
          )}
        />

        <Controller
          name={props.type}
          control={props.control}
          rules={{
            validate: (field) =>
              field.name !== "" || "Location name is required",
          }}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id={`${props.type}.name`}
              control={Search}
              input={{ icon: "search", iconPosition: "left" }}
              label="Location Name"
              placeholder="Location Name"
              width={5}
              onSearchChange={onSearchChange}
              onResultSelect={(
                _: FormEvent<HTMLInputElement>,
                { result }: { result: LocationResultObject }
              ) => {
                const location = turvoLocations.find(
                  (location) => location.id === result.id
                );

                if (location) {
                  onChange({
                    ...value,
                    id: location.id,
                    name: location.name,
                    formatted: location.formatted,
                    address: location.address,
                    city: location.city,
                    state: location.state,
                    zip: location.zip,
                    lat: location.lat,
                    lon: location.lon,
                    appointment: {
                      ...value.appointment,
                      timezone: location.timezone,
                    },
                  });
                  setLocationSearch(location.name ?? "");
                }
              }}
              loading={turvoLocationsLoading === "pending"}
              results={turvoLocations.map((location) => ({
                id: location.id,
                title: location.name,
                description: location.formatted,
              }))}
              value={locationSearch}
              fluid
              required
            />
          )}
        />
        <Controller
          name={`${props.type}.formatted`}
          control={props.control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Form.Field
              id={`${props.type}.formatted`}
              control={Input}
              label="Address"
              placeholder="Address"
              width={7}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: InputOnChangeData
              ) => onChange(value)}
              value={props.route.formatted}
              required
            />
          )}
        />
      </Form.Group>

      <Form.Group>
        <Controller
          name={`${props.type}.appointment.startDate`}
          control={props.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id={`${props.type}.appointment.startDate`}
              control={DatePicker}
              onChange={onChange}
              selected={value}
              label="Earliest Appt Date"
              width={2}
              required
              placeholderText="Date"
            />
          )}
        />
        <Controller
          name={`${props.type}.appointment.startDate`}
          control={props.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id={`${props.type}.appointment.startTime`}
              control={DatePicker}
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              onChange={onChange}
              selected={value}
              label="Time"
              width={2}
              required
              placeholderText="Time"
            />
          )}
        />
        {!props.startDateOnly ? (
          <>
            <Controller
              name={`${props.type}.appointment.endDate`}
              control={props.control}
              rules={{ required: !props.startDateOnly }}
              render={({ field: { onChange, value } }) => (
                <Form.Field
                  id={`${props.type}.appointment.endDate`}
                  control={DatePicker}
                  onChange={onChange}
                  selected={value}
                  label="Latest Appt Date"
                  width={2}
                  required={!props.startDateOnly}
                  placeholderText="Date"
                />
              )}
            />
            <Controller
              name={`${props.type}.appointment.endDate`}
              control={props.control}
              rules={{ required: !props.startDateOnly }}
              render={({ field: { onChange, value } }) => (
                <Form.Field
                  id={`${props.type}.appointment.endTime`}
                  control={DatePicker}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  onChange={onChange}
                  selected={value}
                  label="Time"
                  width={2}
                  required={!props.startDateOnly}
                  placeholderText="Time"
                />
              )}
            />
          </>
        ) : null}

        <Controller
          name={`${props.type}.notes`}
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id={`${props.type}.notes`}
              control={Input}
              label="Notes"
              placeholder="Notes"
              width={8}
              value={value}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: InputOnChangeData
              ) => onChange(value)}
            />
          )}
        />
      </Form.Group>
    </>
  );
};

export default RouteItem;
