import { useEffect } from "react";
import { Header, Divider, Icon, Loader, Dimmer } from "semantic-ui-react";
import { Control } from "react-hook-form";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import RouteItem from "./RouteItem";
import { FormLocationObject } from "../../models/FormLocationObject";
import { fetchDistance, resetDistance } from "../../store/geocodingSlice";
import { BaseFormObject } from "../../models/BaseFormObject";

const DividerStyled = styled(Divider)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
`;

const HeaderStyled = styled(Header)`
  margin-bottom: 0px !important;
`;

const RoutesContainer = styled.div`
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-template-rows: 150px 100px;
  grid-template-areas: "distance origin" "distance destination";
`;

const DistanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: distance;
`;

const PickupContainerStyled = styled.div`
  grid-area: origin;
`;

const DeliveryContainerStyled = styled.div`
  grid-area: destination;
`;

const DistanceLine = styled.div`
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  height: 130px;
  margin-right: 16px;
`;

const GreenCircleIconStyled = styled(Icon)`
  margin-left: 50px !important;
  margin-bottom: 5px !important;
`;

const RedCircleIconStyled = styled(Icon)`
  margin-left: 50px !important;
`;

const MilesSpanStyled = styled.span`
  line-height: 115px;
  font-size: 0.85em;
  line-height: 130px;
`;

const DistanceLoaderContainer = styled.div`
  position: absolute;
  top: 330px;
  left: 10px;
`;

export type RoutesSectionProps = {
  control: Control<BaseFormObject>;
  pickup: FormLocationObject;
  delivery: FormLocationObject;
  startDateOnly: boolean;
};

const RoutesSection = (props: RoutesSectionProps) => {
  const dispatch = useAppDispatch();
  const geocodingStatus = useAppSelector((state) => state.geocoding.status);
  const distance = useAppSelector((state) => state.geocoding.distance);

  useEffect(() => {
    dispatch(resetDistance());
  }, []);

  useEffect(() => {
    if (
      props.pickup.lat &&
      props.pickup.lon &&
      props.delivery.lat &&
      props.delivery.lon
    ) {
      const points = [
        { lat: props.pickup.lat, lon: props.pickup.lon },
        { lat: props.delivery.lat, lon: props.delivery.lon },
      ];

      dispatch(fetchDistance(points))
        .unwrap()
        .catch((err) => {
          console.error(err);
        });
    }
  }, [
    props.pickup.lat,
    props.pickup.lon,
    props.delivery.lat,
    props.delivery.lon,
    dispatch,
  ]);

  return (
    <>
      <DistanceLoaderContainer>
        <Dimmer active={geocodingStatus === "pending"} inverted>
          <Loader />
        </Dimmer>
      </DistanceLoaderContainer>
      <HeaderStyled as="h3">Routes</HeaderStyled>

      <DividerStyled />

      <RoutesContainer>
        <DistanceContainer>
          <GreenCircleIconStyled name="circle" color="green" />
          <DistanceLine>
            {geocodingStatus === "fulfilled" ? (
              <MilesSpanStyled>
                {distance.toLocaleString(undefined, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })}{" "}
                mi
              </MilesSpanStyled>
            ) : null}
          </DistanceLine>
          <RedCircleIconStyled name="circle" color="red" />
        </DistanceContainer>

        <PickupContainerStyled>
          <RouteItem
            type="pickup"
            control={props.control}
            route={props.pickup}
            startDateOnly={props.startDateOnly}
          />
        </PickupContainerStyled>

        <DeliveryContainerStyled>
          <RouteItem
            type="delivery"
            control={props.control}
            route={props.delivery}
            startDateOnly={props.startDateOnly}
          />
        </DeliveryContainerStyled>
      </RoutesContainer>
    </>
  );
};

export default RoutesSection;
