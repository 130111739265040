import styled from "styled-components";
import { Header, Form, Divider, Input, Select } from "semantic-ui-react";
import { Controller, Control, UseFormSetValue } from "react-hook-form";

import { MappedEnumItem } from "../../../../models/MappedEnumItem";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { OrderFormObject } from "../../../../models/OrderFormObject";
import { FormKeyValueObject } from "../../../../models/OrderFormKeyValue";
import { ChangeEvent } from "react";
import { getVinInfo } from "../../../../store/vinSlice";
import { VEHICLE_TYPE_KEY } from ".";
import { mapEnumToOption } from "./DetailsModal";

const StyledDivider = styled(Divider)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 0px !important;
`;

const dimensionUnits = [{ key: 1, value: 1, text: "Inches" }];
const weightUnits = [{ key: 1, value: 1, text: "Pounds" }];

export type ItemsSectionProps = {
  control: Control<OrderFormObject>;
  setValue: UseFormSetValue<OrderFormObject>;
  itemCategory: FormKeyValueObject;
};

const ItemsSection = (props: ItemsSectionProps) => {
  const dispatch = useAppDispatch();
  const vinStatus = useAppSelector((state) => state.vin.status);
  const itemCategoryTypes = useAppSelector(
    (state) => state.orders.additionalData.itemCategoryTypes
  ).map<MappedEnumItem>(mapEnumToOption);
  const additionalDataStatus = useAppSelector(
    (state) => state.orders.additionalDataStatus
  );

  const isLoadingAdditionalData = additionalDataStatus === "pending";
  const isLoadingVin = vinStatus === "pending";

  const loadVinInfo = (vin: string) => {
    if (vin.length === 17 && props.itemCategory.key === VEHICLE_TYPE_KEY) {
      dispatch(getVinInfo(vin))
        .unwrap()
        .then((vinInfo) => {
          props.setValue("item.make", vinInfo.make ?? "");
          props.setValue("item.model", vinInfo.model ?? "");
          props.setValue("item.year", vinInfo.year ?? "");
          props.setValue("item.length", vinInfo.length ?? "");
          props.setValue("item.weight", vinInfo.weight ?? "");
          props.setValue("item.width", vinInfo.width ?? "");
          props.setValue("item.height", vinInfo.height ?? "");
          props.setValue("item.wheelbase", vinInfo.wheelBase ?? "");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <StyledHeader as="h3">Items</StyledHeader>

      <StyledDivider />

      <Form.Group>
        <Controller
          name="item.name"
          control={props.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.name"
              control={Input}
              label="Item Name/VIN"
              placeholder="Item Name/VIN"
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => {
                onChange(value);
                loadVinInfo(value);
              }}
              value={value}
              width={3}
              loading={isLoadingVin}
              required
            />
          )}
        />

        <Controller
          name="item.qty"
          control={props.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.qty"
              control={Input}
              label="Qty"
              placeholder="Qty"
              width={2}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
              required
            />
          )}
        />

        <Controller
          name="item.itemCategory"
          control={props.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.itemCategory"
              control={Select}
              label="Type"
              placeholder="Type"
              options={itemCategoryTypes}
              loading={isLoadingAdditionalData}
              width={2}
              fluid
              required
              onChange={(
                _: React.ChangeEvent,
                { value }: { value: string }
              ) => {
                const selectedItemType = itemCategoryTypes.find(
                  (type) => type.value === value
                ) ?? { value: "", text: "" };
                onChange({
                  key: selectedItemType.value,
                  value: selectedItemType.text,
                });
              }}
              value={value.key}
              search={(options: MappedEnumItem[], value: string) =>
                options.filter((option) =>
                  new RegExp(`^${value}`, "mi").test(option.text)
                )
              }
            />
          )}
        />

        <Form.Field id="item.whitespace1" control="div" width={1} />

        <Controller
          name="item.length"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.length"
              control={Input}
              disabled={isLoadingVin}
              label="Length"
              placeholder="Length"
              width={2}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
            />
          )}
        />

        <Controller
          name="item.width"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.width"
              control={Input}
              disabled={isLoadingVin}
              label="Width"
              placeholder="Width"
              width={2}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
            />
          )}
        />

        <Controller
          name="item.height"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.height"
              control={Input}
              disabled={isLoadingVin}
              label="Height"
              placeholder="Height"
              width={2}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
            />
          )}
        />

        <Controller
          name="item.wheelbase"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.wheelbase"
              control={Input}
              disabled={isLoadingVin}
              label="Wheelbase"
              placeholder="Wheelbase"
              width={2}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
            />
          )}
        />

        <Form.Field
          id="item.dimension.units"
          control={Select}
          label="Dimension Units"
          placeholder="Dimension"
          width={2}
          fluid
          options={dimensionUnits}
          value={1}
          search={(options: MappedEnumItem[], value: string) =>
            options.filter((option) =>
              new RegExp(`^${value}`, "mi").test(option.text)
            )
          }
        />
      </Form.Group>

      <Form.Group>
        <Controller
          name="item.make"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.make"
              control={Input}
              disabled={isLoadingVin}
              label="Make"
              placeholder="Make"
              width={3}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
            />
          )}
        />

        <Controller
          name="item.model"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.model"
              control={Input}
              disabled={isLoadingVin}
              label="Model"
              placeholder="Model"
              width={2}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
            />
          )}
        />

        <Controller
          name="item.year"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.year"
              control={Input}
              disabled={isLoadingVin}
              label="Year"
              placeholder="Year"
              width={2}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
            />
          )}
        />

        <Form.Field id="item.whitespace2" control="div" width={1} />

        <Controller
          name="item.weight"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.weight"
              control={Input}
              disabled={isLoadingVin}
              label="Weight"
              placeholder="Weight"
              width={8}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
            />
          )}
        />

        <Form.Field
          id="item.weight.units"
          control={Select}
          label="Weight Units"
          placeholder="Weight"
          width={2}
          fluid
          options={weightUnits}
          value={1}
          search={(options: MappedEnumItem[], value: string) =>
            options.filter((option) =>
              new RegExp(`^${value}`, "mi").test(option.text)
            )
          }
        />
      </Form.Group>

      <Form.Group>
        <Controller
          name="item.itemNumber"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.itemNumber"
              control={Input}
              label="Ref#"
              placeholder="Ref#"
              width={5}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
            />
          )}
        />

        <Controller
          name="item.notes"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="item.notes"
              control={Input}
              label="Notes"
              placeholder="Notes"
              width={11}
              fluid
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: { value: string }
              ) => onChange(value)}
              value={value}
            />
          )}
        />
      </Form.Group>
    </>
  );
};

export default ItemsSection;
