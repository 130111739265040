import { useState, useMemo, ChangeEvent, useEffect } from "react";
import {
  Header,
  Form,
  Divider,
  Search,
  Input,
  InputOnChangeData,
} from "semantic-ui-react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import styled from "styled-components";
import debounce from "lodash-es/debounce";
import { getTurvoCustomers } from "../../../store/turvoCustomerSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ShellShipmentFormObject } from "../../../models/ShellShipmentFormObject";

const StyledDivider = styled(Divider)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 0px !important;
`;

export type CustomerSectionProps = {
  control: Control<ShellShipmentFormObject>;
  customerName: string;
  orderQty: string;
  setValue: UseFormSetValue<ShellShipmentFormObject>;
};

const CustomerSection = (props: CustomerSectionProps) => {
  const dispatch = useAppDispatch();
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [customerSearch, setCustomerSearch] = useState<string>(
    props.customerName
  );

  useEffect(() => {
    setCustomerSearch(props.customerName);
  }, [props.customerName]);

  const turvoCustomers = useAppSelector(
    (state) => state.turvoCustomer.customers
  );

  const turvoCustomersStatus = useAppSelector(
    (state) => state.turvoCustomer.status
  );

  const debouncedSearchChange = useMemo(
    () =>
      debounce((value: string) => {
        dispatch(getTurvoCustomers(value))
          .unwrap()
          .then(() => setSearchOpen(true))
          .catch((err) => {
            console.error(err);
          });
      }, 750),
    [dispatch, getTurvoCustomers]
  );

  const onSearchChange = (
    _: ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    setCustomerSearch(value);
    debouncedSearchChange(value);
  };

  return (
    <>
      <StyledHeader as="h3">Customer</StyledHeader>
      <StyledDivider />
      <Form.Group>
        <Controller
          name="customerName"
          control={props.control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Form.Field
              id="customerName"
              input={{ icon: "search", iconPosition: "left" }}
              control={Search}
              onSearchChange={onSearchChange}
              onResultSelect={(
                event: ChangeEvent<HTMLInputElement>,
                { result }: InputOnChangeData
              ) => {
                setSearchOpen(false);
                props.setValue("customerId", parseInt(result.id));
                props.setValue("customerName", result.title);
                onChange(result.title);
                event.stopPropagation();
              }}
              loading={turvoCustomersStatus === "pending"}
              results={turvoCustomers.map((customer) => ({
                id: customer.id,
                title: customer.name,
              }))}
              value={customerSearch}
              placeholder="Customer"
              label="Customer"
              width={10}
              fluid
              required
              open={searchOpen}
              onClick={() => setSearchOpen(true)}
              onBlur={() => setSearchOpen(false)}
            />
          )}
        />

        <Controller
          name="orderQty"
          control={props.control}
          rules={{
            required: true,
            min: 1,
            max: 9,
            validate: (value) => /^[1-9]$/.test(value.toString()),
          }}
          render={({ field: { onChange } }) => (
            <Form.Field
              id="orderQty"
              control={Input}
              label="Order Quantity"
              placeholder="0"
              width={2}
              required
              value={props.orderQty}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: InputOnChangeData
              ) => onChange(value)}
            ></Form.Field>
          )}
        ></Controller>
      </Form.Group>
    </>
  );
};

export default CustomerSection;
