import {
  Header,
  Form,
  Divider,
  Input,
  Select,
  InputOnChangeData,
} from "semantic-ui-react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import styled from "styled-components";

import { MappedEnumItem } from "../../../../models/MappedEnumItem";
import { OrderFormObject } from "../../../../models/OrderFormObject";
import { useAppSelector } from "../../../../store/hooks";
import { ChangeEvent } from "react";
import { mapEnumToOption } from "./DetailsModal";

const StyledDivider = styled(Divider)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 0px !important;
`;

export type RequirementsSegmentProps = {
  control: Control<OrderFormObject>;
  setValue: UseFormSetValue<OrderFormObject>;
};

const RequirementsSegment = (props: RequirementsSegmentProps) => {
  const equipmentTypes = useAppSelector(
    (state) => state.orders.additionalData.equipmentTypes
  ).map<MappedEnumItem>(mapEnumToOption);
  const externalIdTypes = useAppSelector(
    (state) => state.orders.additionalData.externalIdTypes
  ).map<MappedEnumItem>(mapEnumToOption);
  const additionalDataStatus = useAppSelector(
    (state) => state.orders.additionalDataStatus
  );

  const isLoadingAdditionalData = additionalDataStatus === "pending";

  return (
    <>
      <StyledHeader as="h3">Requirements</StyledHeader>
      <StyledDivider />

      <Form.Group>
        <Controller
          name="equipment.type"
          control={props.control}
          rules={{
            validate: (type) => type.key !== "" || "Equipment is required",
          }}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="equipment.type"
              control={Select}
              label="Equipment"
              placeholder="Equipment"
              options={equipmentTypes}
              loading={isLoadingAdditionalData}
              value={value.key}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: InputOnChangeData
              ) => {
                const selectedEquipment = equipmentTypes.find(
                  (type) => type.value === value
                ) ?? { value: "", text: "" };
                onChange({
                  key: selectedEquipment.value,
                  value: selectedEquipment.text,
                });
              }}
              width={8}
              required
              search={(options: MappedEnumItem[], value: string) =>
                options.filter((option) =>
                  new RegExp(`^${value}`, "mi").test(option.text)
                )
              }
            />
          )}
        />
        <Controller
          name="externalId.value"
          control={props.control}
          render={({ field: { onChange, value } }) => {
            return (
              <Form.Field
                id="externalId.value"
                control={Input}
                label="PO#"
                placeholder="PO#"
                width={4}
                value={value}
                onChange={(
                  _: ChangeEvent<HTMLInputElement>,
                  { value }: InputOnChangeData
                ) => onChange(value)}
              />
            );
          }}
        />
        <Controller
          name="externalId.type"
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <Form.Field
              id="externalId.type"
              control={Select}
              label="Type"
              placeholder="Type"
              options={externalIdTypes}
              loading={isLoadingAdditionalData}
              value={value.key}
              width={4}
              clearable
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: InputOnChangeData
              ) => {
                const selectedExternalId = externalIdTypes.find(
                  (type) => type.value === value
                ) ?? { value: "", text: "" };
                onChange({
                  key: selectedExternalId.value,
                  value: selectedExternalId.text,
                });
                if (!value) {
                  props.setValue("externalId.value", "");
                }
              }}
              search={(options: MappedEnumItem[], value: string) =>
                options.filter((option) =>
                  new RegExp(`^${value}`, "mi").test(option.text)
                )
              }
            />
          )}
        />
      </Form.Group>
    </>
  );
};

export default RequirementsSegment;
