import { FormCostObject } from "../models/FormCostObject";
import { ShellShipmentFormObject } from "../models/ShellShipmentFormObject";

export const getDefaultShellShipment = (): ShellShipmentFormObject => {
  return {
    customerId: 0,
    customerName: "",
    orderQty: "0",
    pickup: {
      name: "",
      formatted: "",
      shortFormatted: "",
      city: "",
      state: "",
      zip: "",
      address: "",
      lat: 0,
      lon: 0,
      poNumber: "",
      appointment: {
        scheduling: "9400",
        timezone: "",
        startDate: null,
        endDate: null,
      },
      notes: "",
    },
    delivery: {
      name: "",
      formatted: "",
      shortFormatted: "",
      city: "",
      state: "",
      zip: "",
      address: "",
      lat: 0,
      lon: 0,
      poNumber: "",
      appointment: {
        scheduling: "",
        timezone: "",
        startDate: null,
        endDate: null,
      },
      notes: "",
    },
    costs: [getDefaultCost()],
  };
};

export const getDefaultCost = (): FormCostObject => ({
  id: crypto.randomUUID(),
  code: {
    key: "",
    value: "",
  },
  qty: "0",
  note: "",
  price: "0",
  amount: "0",
});
