import { Dispatch, SetStateAction, useEffect } from "react";
import { Modal, Button, Message, Form } from "semantic-ui-react";
import { useForm, Control, UseFormSetValue } from "react-hook-form";
import styled from "styled-components";

import CustomerSection from "./CustomerSection";
import RequirementsSegment from "./RequirementsSegment";
import RoutesSection from "../../../Shared/RoutesSection";
import ItemsSection from "./ItemsSection";
import PriceSection from "../../../Shared/PriceSection";

import { useAppSelector } from "../../../../store/hooks";
import { OrderFormObject } from "../../../../models/OrderFormObject";
import { EnumItem } from "../../../../models/EnumItem";
import { MappedEnumItem } from "../../../../models/MappedEnumItem";
import { BaseFormObject } from "../../../../models/BaseFormObject";

const ModalStyled = styled(Modal)`
  min-width: 1200px;
`;

const ModalContentStyled = styled(Modal.Content)`
  max-height: 65vh;
  overflow: auto;
`;

type DetailsModalProps = {
  open: boolean;
  order: OrderFormObject;
  onCloseFormError: () => void;
  onSave: (orderFormObject: OrderFormObject) => void;
  onClose: () => void;
  onCancel: () => void;
  onOpen: Dispatch<SetStateAction<boolean>>;
};

const DetailsModal = (props: DetailsModalProps) => {
  const form = useAppSelector((state) => state.orders.form);
  const formStatus = useAppSelector((state) => state.orders.formStatus);

  const isLoadingForm = formStatus === "pending";

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<OrderFormObject>({
    defaultValues: { ...props.order },
  });

  useEffect(() => {
    reset({ ...props.order });
  }, [props.order]);

  const handleOrderSave = (orderFormObject: OrderFormObject) => {
    props.onSave(orderFormObject);
  };

  return (
    <ModalStyled
      onClose={props.onCancel}
      onOpen={props.onOpen}
      open={props.open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>{`${
        props.order.id === 0 ? "Create" : "Update"
      } order`}</Modal.Header>
      <ModalContentStyled>
        {form.errors && (
          <Message
            onDismiss={props.onCloseFormError}
            header="Error!"
            content={`Error occurred during order ${
              props.order.id === 0 ? "creation" : "update"
            }: ${form.errors}`}
            negative
          />
        )}
        {form.success && (
          <Message
            header="Success"
            content={`Order ${props.order.item.name} successfully ${
              props.order.id === 0 ? "created" : "updated"
            }`}
            success
          />
        )}
        {!form.success && (
          <Form
            loading={isLoadingForm}
            onSubmit={handleSubmit(handleOrderSave)}
            id="orderForm"
            size="tiny"
          >
            <CustomerSection control={control} customer={watch("customer")} />

            <RequirementsSegment control={control} setValue={setValue} />

            <RoutesSection
              control={control}
              pickup={watch("pickup")}
              delivery={watch("delivery")}
              startDateOnly={false}
            />

            <ItemsSection
              control={control}
              setValue={setValue}
              itemCategory={watch("item.itemCategory")}
            />

            <PriceSection
              control={control as unknown as Control<BaseFormObject>}
              setValue={setValue as unknown as UseFormSetValue<BaseFormObject>}
              costs={watch("costs")}
              customerId={watch("customer.id")}
              areCostsRequired={false}
            />
          </Form>
        )}
      </ModalContentStyled>
      <Modal.Actions>
        {!form.success ? (
          <>
            <Button
              disabled={isLoadingForm}
              basic
              onClick={props.onCancel}
              content="Cancel"
            />
            <Button
              disabled={isLoadingForm || !isValid}
              type="submit"
              value="submit"
              content="Save"
              form="orderForm"
              primary
            />
          </>
        ) : (
          <Button basic onClick={props.onClose} content="Close" />
        )}
      </Modal.Actions>
    </ModalStyled>
  );
};

export default DetailsModal;

export const mapEnumToOption = (item: EnumItem): MappedEnumItem => ({
  key: item.value,
  value: item.value,
  text: item.text,
});
