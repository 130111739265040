import { OrderObject } from "../../../../../models/OrderObject";
import { VEHICLE_TYPE_KEY } from "..";
import { OrderItem } from "../../../../../models/OrderItem";
import { OrderEquipmentObject } from "../../../../../models/OrderEquipmentObject";
import { OrderLocation } from "../../../../../models/OrderLocation";
import { convertToTimeZoneDate } from "./dateConversion";
import { OrderCostObject } from "../../../../../models/OrderCostObject";
import {
  OrderFormEquipment,
  OrderFormItem,
  OrderFormObject,
} from "../../../../../models/OrderFormObject";
import { FormCostObject } from "../../../../../models/FormCostObject";
import { FormLocationObject } from "../../../../../models/FormLocationObject";

const mapEquipment = (
  orderEquipmentObject: OrderEquipmentObject
): OrderFormEquipment => {
  return {
    type: {
      key: orderEquipmentObject.type?.key ?? "",
      value: orderEquipmentObject.type?.value ?? "",
    },
  };
};

const mapItem = (orderItem: OrderItem): OrderFormItem => {
  return {
    itemCategory: {
      key: orderItem.itemCategory?.key ?? VEHICLE_TYPE_KEY,
      value: orderItem.itemCategory?.value ?? "Vehicle",
    },
    name: orderItem.name ?? "",
    qty: orderItem.qty?.toString() ?? "",
    make: orderItem.make ?? "",
    model: orderItem.model ?? "",
    year: orderItem.year ?? "",
    identityNumber: orderItem.identityNumber ?? "",
    itemNumber: orderItem.itemNumber ?? "",
    notes: orderItem.notes ?? "",
    length: orderItem.length?.toString() ?? "",
    width: orderItem.width?.toString() ?? "",
    height: orderItem.height?.toString() ?? "",
    weight: orderItem.weight?.toString() ?? "",
    wheelbase: orderItem.wheelbase?.toString() ?? "",
  };
};

const mapLocation = (orderLocation: OrderLocation): FormLocationObject => {
  const startDate =
    orderLocation.appointment?.start && orderLocation.appointment.timezone
      ? convertToTimeZoneDate({
          utcDateString: orderLocation.appointment.start,
          timeZone: orderLocation.appointment.timezone,
        })
      : null;

  const endDate =
    orderLocation.appointment?.end && orderLocation.appointment.timezone
      ? convertToTimeZoneDate({
          utcDateString: orderLocation.appointment.end,
          timeZone: orderLocation.appointment.timezone,
        })
      : null;

  return {
    name: orderLocation.name,
    formatted: orderLocation.formatted ?? "",
    shortFormatted: orderLocation.shortFormatted ?? "",
    city: orderLocation.city ?? "",
    state: orderLocation.state ?? "",
    zip: orderLocation.zip ?? "",
    address: orderLocation.address ?? "",
    lat: orderLocation.lat ?? 0,
    lon: orderLocation.lon ?? 0,
    poNumber: orderLocation.poNumbers?.[0] ?? "",
    notes: orderLocation.notes ?? "",
    appointment: {
      scheduling: "9400",
      startDate,
      endDate,
      timezone: orderLocation.appointment?.timezone ?? "",
    },
  };
};

const mapCost = (orderCostObject: OrderCostObject): FormCostObject => {
  return {
    id: crypto.randomUUID(),
    code: {
      key: orderCostObject.code?.key ?? "",
      value: orderCostObject.code?.value ?? "",
    },
    qty: orderCostObject.qty?.toFixed(2) ?? "",
    price: orderCostObject?.price?.toFixed(2) ?? "",
    amount: orderCostObject?.amount?.toFixed(2) ?? "",
    note: orderCostObject.note ?? "",
  };
};

const mapApiToFormOrder = (orderApiObject: OrderObject): OrderFormObject => {
  const customer = {
    id: orderApiObject.customer?.id ?? null,
    name: orderApiObject.customer?.name ?? "",
    statusName: "",
  };

  const existingExternalId = orderApiObject.externalIds?.[0];
  const externalId = {
    type: {
      key: existingExternalId?.type?.key ?? "",
      value: existingExternalId?.type?.value ?? "",
    },
    value: existingExternalId?.value ?? "",
  };

  const item = orderApiObject.items?.[0]
    ? mapItem(orderApiObject.items[0])
    : {
        itemCategory: {
          key: VEHICLE_TYPE_KEY,
          value: "Vehicle",
        },
        name: "",
        qty: "",
        make: "",
        model: "",
        year: "",
        identityNumber: "",
        itemNumber: "",
        notes: "",
        length: "",
        width: "",
        height: "",
        weight: "",
        wheelbase: "",
      };

  const equipment = orderApiObject.equipment
    ? mapEquipment(orderApiObject.equipment)
    : {
        type: {
          key: "",
          value: "",
        },
      };

  const orderFormObject = {
    id: orderApiObject.id,
    customer,
    externalId,
    item,
    equipment,
    pickup: mapLocation(orderApiObject.pickup),
    delivery: mapLocation(orderApiObject.delivery),
    costs: orderApiObject.costs?.map(mapCost) ?? [],
  };

  return orderFormObject;
};

export default mapApiToFormOrder;
