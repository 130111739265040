import { useEffect, useState } from "react";
import { Modal, Button, Form, Segment } from "semantic-ui-react";
import { Control, useForm, UseFormSetValue } from "react-hook-form";
import { useAppDispatch } from "../../../store/hooks";
import styled from "styled-components";

import { addErrorMessage } from "../../../store/alertMessageSlice";
import { getTurvoCustomers } from "../../../store/turvoCustomerSlice";
import { getTurvoLocations } from "../../../store/turvoLocationSlice";
import { fetchAdditionalData } from "../../../store/orderSlice";
import { ShellShipmentFormObject } from "../../../models/ShellShipmentFormObject";
import { getDefaultShellShipment } from "../../../utils/DefaultShellShipmentObjects";
import CustomerSection from "./CustomerSection";
import RouteSection from "../../Shared/RoutesSection";
import PriceSection from "../../Shared/PriceSection";
import {
  buildShellShipment,
  clearAllSelectedShipments,
  clearShipments,
  getShipments,
  postListStatistics,
} from "../../../store/shipmentSlice";
import { ShipmentBuildItem } from "../../../models/ShipmentBuildItem";
import mapFormToApiShipment from "./utils/mapFormToApiShipment";
import { BaseFormObject } from "../../../models/BaseFormObject";

const ModalStyled = styled(Modal)`
  min-width: 1200px;
`;

const ModalContentStyled = styled(Modal.Content)`
  max-height: 65vh;
  overflow: auto;
`;

type ShipmentPanelCreateShellModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const ShipmentPanelCreateShellModal = (
  props: ShipmentPanelCreateShellModalProps
) => {
  const dispatch = useAppDispatch();
  const { isOpen, onClose, onOpen } = props;
  const {
    control,
    watch,
    setValue,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<ShellShipmentFormObject>({
    defaultValues: { ...getDefaultShellShipment() },
  });
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<ShipmentBuildItem | null>(null);

  const handleClose = () => {
    reset();
    onClose();
    setErrors(null);
    setLoading(false);
    setSuccess(null);
  };

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    dispatch(getTurvoCustomers())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });

    dispatch(getTurvoLocations())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });

    dispatch(fetchAdditionalData())
      .unwrap()
      .catch((err) => {
        dispatch(
          addErrorMessage(`Unable to fetch additional data. Error: ${err}`)
        );
      });
  }, [isOpen, dispatch]);

  const handleShellShipmentCreation = () => {
    setLoading(true);

    const formData = watch();

    dispatch(buildShellShipment(mapFormToApiShipment(formData)))
      .unwrap()
      .then((buildResult) => {
        if (buildResult) {
          dispatch(clearShipments());
          dispatch(clearAllSelectedShipments());
          dispatch(getShipments())
            .unwrap()
            .catch((err) => {
              console.error(err);
            });
          dispatch(postListStatistics());
          setSuccess(buildResult);
        }
      })
      .catch((err) => {
        setErrors(err);
        setSuccess(null);
        onOpen();
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalStyled
      open={props.isOpen}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Shell Shipment Creation</Modal.Header>

      <ModalContentStyled>
        {errors && (
          <Segment inverted color="red">
            <h3>Shell Shipment creation request failed with error: {errors}</h3>
            <h4>Please close and try again at a later time.</h4>
          </Segment>
        )}

        {!errors && !success && (
          <Form
            id="createShellForm"
            onSubmit={handleSubmit(handleShellShipmentCreation)}
          >
            <CustomerSection
              control={control}
              customerName={watch("customerName")}
              orderQty={watch("orderQty")}
              setValue={setValue}
            />

            <RouteSection
              control={control as unknown as Control<BaseFormObject>}
              pickup={watch("pickup")}
              delivery={watch("delivery")}
              startDateOnly={true}
            />

            <PriceSection
              control={control as unknown as Control<BaseFormObject>}
              setValue={setValue as unknown as UseFormSetValue<BaseFormObject>}
              costs={watch("costs")}
              customerId={watch("customerId").toString()}
              areCostsRequired={true}
            />
          </Form>
        )}

        {success && (
          <Segment color="green">
            <h3>Shipment Created!</h3>
            <label>
              Go to Turvo Shipment:{" "}
              <a href={success.resourceLink} target="_blank">
                {success.shipmentCustomId}
              </a>
            </label>
          </Segment>
        )}
      </ModalContentStyled>

      <Modal.Actions>
        <Button basic onClick={handleClose} content="Close" />

        {!success && (
          <Button
            loading={loading}
            disabled={!isValid || loading}
            content="Create"
            form="createShellForm"
            type="submit"
            value="submit"
            primary
          />
        )}
      </Modal.Actions>
    </ModalStyled>
  );
};

export default ShipmentPanelCreateShellModal;
