import { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";

import DetailsModal from "./DetailsModal";
import { getTurvoCustomers } from "../../../../store/turvoCustomerSlice";
import { getTurvoLocations } from "../../../../store/turvoLocationSlice";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
  closeOrderUpdateForm,
  createOrder,
  fetchAdditionalData,
  receiveOrderCreationErrors,
  refreshOrders,
  updateOrder,
} from "../../../../store/orderSlice";

import { addErrorMessage } from "../../../../store/alertMessageSlice";

import mapFormToApiOrder from "./utils/mapFormToApiOrder";
import mapApiToFormOrder from "./utils/mapApiToFormOrder";
import getDefaultOrder from "./utils/getDefaultOrder";
import { OrderFormObject } from "../../../../models/OrderFormObject";

export const VEHICLE_TYPE_KEY = "22301";

const OrderPanelOrderDetailsModal = () => {
  const dispatch = useAppDispatch();

  const [order, setOrder] = useState<OrderFormObject>(getDefaultOrder());
  const [formOpen, setFormOpen] = useState<boolean>(false);

  const form = useAppSelector((state) => state.orders.form);
  const apiOrders = useAppSelector((state) => state.orders.orders);

  useEffect(() => {
    if (form.orderId === null) {
      return;
    }

    const selectedOrder = apiOrders.find((x) => x.id === form.orderId);

    if (selectedOrder) {
      const mappedSelectedOrder = mapApiToFormOrder(selectedOrder);

      setFormOpen(true);
      setOrder(mappedSelectedOrder);
    }
  }, [form.orderId, apiOrders]);

  useEffect(() => {
    if (!formOpen) return;

    dispatch(getTurvoCustomers())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
    dispatch(getTurvoLocations())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
    dispatch(fetchAdditionalData())
      .unwrap()
      .catch((err) => {
        dispatch(
          addErrorMessage(`Unable to fetch additional data. Error: ${err}`)
        );
      });
  }, [formOpen, dispatch]);

  const handleCloseFormError = () => {
    dispatch(receiveOrderCreationErrors(""));
  };

  const handleSaveForm = (orderFormObject: OrderFormObject) => {
    const apiOrderObject = mapFormToApiOrder(orderFormObject);

    if (apiOrderObject.id === 0) {
      dispatch(createOrder(apiOrderObject))
        .unwrap()
        .catch((err) => {
          console.error(err);
        });
    } else {
      dispatch(updateOrder(apiOrderObject))
        .unwrap()
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleCloseForm = () => {
    dispatch(closeOrderUpdateForm());
    dispatch(refreshOrders());
    setOrder(getDefaultOrder());
    setFormOpen(false);
  };

  const handleCancelForm = () => {
    dispatch(closeOrderUpdateForm());
    setOrder(getDefaultOrder());
    setFormOpen(false);
  };

  const handleCreateOpen = () => {
    setFormOpen(true);
  };

  return (
    <>
      <Button
        size="mini"
        onClick={handleCreateOpen}
        icon="plus"
        content="New Order"
      />
      <DetailsModal
        order={order}
        open={formOpen}
        onOpen={setFormOpen}
        onCloseFormError={handleCloseFormError}
        onSave={handleSaveForm}
        onClose={handleCloseForm}
        onCancel={handleCancelForm}
      />
    </>
  );
};

export default OrderPanelOrderDetailsModal;
