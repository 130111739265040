import { numberOrNull } from "../../../OrderPanel/ApiOrderDataMapper";
import { ShellShipmentFormObject } from "../../../../models/ShellShipmentFormObject";
import { ShellShipmentPostObject } from "../../../../models/ShellShipmentPostObject";

const mapFormToApiShipment = (
  shellShipmentFormObject: ShellShipmentFormObject
): ShellShipmentPostObject => {
  return {
    customerId: shellShipmentFormObject.customerId,
    customerName: shellShipmentFormObject.customerName,
    pickup: {
      ...shellShipmentFormObject.pickup,
      appointment: {
        scheduling: shellShipmentFormObject.pickup.appointment.scheduling,
        start: shellShipmentFormObject.pickup.appointment.startDate,
        end:
          shellShipmentFormObject.pickup.appointment.endDate ??
          shellShipmentFormObject.pickup.appointment.startDate,
        timezone: shellShipmentFormObject.pickup.appointment.timezone,
      },
    },
    delivery: {
      ...shellShipmentFormObject.delivery,
      appointment: {
        scheduling: shellShipmentFormObject.delivery.appointment.scheduling,
        start: shellShipmentFormObject.delivery.appointment.startDate,
        end:
          shellShipmentFormObject.delivery.appointment.endDate ??
          shellShipmentFormObject.delivery.appointment.startDate,
        timezone: shellShipmentFormObject.delivery.appointment.timezone,
      },
    },
    orderQty: numberOrNull(shellShipmentFormObject.orderQty),
    costs: shellShipmentFormObject.costs.map((cost) => ({
      ...cost,
      price: numberOrNull(cost.price),
      amount: numberOrNull(cost.amount),
      qty: numberOrNull(cost.qty),
    })),
  };
};

export default mapFormToApiShipment;
